@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.educationsContainer div {
  animation: fadeIn 0.5s ease-out forwards;
  animation-delay: var(--fade-delay, 0s);
}

.educationsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background: #000;
  color: #fff;
  width: 100%;
}

.educationsContainer > div {
  width: 300px;
  max-width: 80%;
  background: #0F0F0F;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
}

.degree {
  font-size: 0.75em;
}

.educationWrap h3 {
  background-color: #212121;
  text-align: center;
  margin: 0;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
