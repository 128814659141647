@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .wrap h3, .wrap h4 {
      color: #ffffff;
      margin-left: 20px;
  }
  
  .sliderContainer {
      background-color: black;
      display: flex;
      overflow-y: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 200px;
      scrollbar-color: #999 #333;
  }
  
  .sliderContainer::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }
  
  .sliderContainer::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #2492E9;
  }
  
  .styled-scrollbars::-webkit-scrollbar-track {
      background: #333;
  }
  
  img {
      max-width: 100%;
  }
  
  .coverImage {
      opacity: 0;
      transition: opacity 0.5s ease-out;
      width: 200px;
      margin-left: 10px;
      border-radius: 10px;
      cursor: pointer;
  }
  
  .coverImage.loaded {
      opacity: 1;
  }
  
  .sliderInner {
      margin-bottom: 10px;
  }
  
  .year{
      background: linear-gradient(to bottom, #2b2b2b 0%, #101010 50%);
  }
  
  .yearNumberContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: #2b2b2b;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  
  @media (max-width: 960px) {
    .coverImage  {
        width: 150px;
    }
    .sliderContainer {
        height: 150px;
    }
  }

  