@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background: #000;
  color: #fff;
  width: 100%;
}

.iconsContainer > div {
  max-width: 80%;
  background: #0F0F0F;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
}

.icons {
  font-size: 0.75em;
}

.iconsWrap h3 {
  background-color: #212121;
  text-align: center;
  margin: 0;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
