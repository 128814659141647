@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes iframeFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
}

.popup-content {
  animation: slideInFromLeft 0.2s ease forwards;
  position: relative;
  width: 960px;
  max-width: 80%;
  margin: 20px;
  background-color: #161616;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.popup-content.hidden {
  animation: slideOutToLeft 0.2s ease forwards;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.video-container iframe {
  animation: iframeFadeIn 1.5s ease forwards;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popupHeadlineContainer {
  display: flex;
}

.popupCoverImage img {
  border-radius: 10px;
  margin-bottom: 10px;
}

.popupCompanyLogo {
  margin-top: 40px;
  display: flex;
  justify-content: right;
}

.popupFooterContainer {
  color: #ffffff;
}

.popupInfo {
  color: #ffffff;
  width: 600px;
  max-width: 100%;
}

.popupInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.popupInfoContainer h4 {
  margin: 0;
  margin-bottom: 20px;
}

.popupTitleContainer {
  display: flex;
  color: #ffffff;
  flex-direction: column;
}

.popupTitleContainer h3,
.popupTitleContainer h4 {
  margin: 0;
  margin-left: 10px;
}

.readMoreLink {
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  color: #007bff;
  text-decoration: none;
}

.readMoreLink:hover {
  text-decoration: underline;
}

.readMoreLink .svg-inline--fa {
  margin-left: 5px;
}
